import { ActionTree } from "vuex";
import { ProfileState } from "./types";
import { RootState } from "../types";
import { User } from "@/store/profile/types";
import firebase from "@/plugins/firebase";

export const actions: ActionTree<ProfileState, RootState> = {
  loginWithGoogle({ commit }): any {
    return firebase.loginWithGoogle().then((userCredential) => {
      if (userCredential.user) {
        const user: User = {
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          phoneNumber: userCredential.user.phoneNumber,
          photoURL: userCredential.user.photoURL,
          providerId: userCredential.user.providerId,
          uid: userCredential.user.uid,
        };
        commit("setUser", user);
        return user;
      }
    });
  },
  loginWithFacebook({ commit }): any {
    return firebase.loginWithFacebook().then((userCredential) => {
      if (userCredential.user) {
        const user: User = {
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          phoneNumber: userCredential.user.phoneNumber,
          photoURL: userCredential.user.photoURL,
          providerId: userCredential.user.providerId,
          uid: userCredential.user.uid,
        };
        commit("setUser", user);
        return user;
      }
    });
  },
  loginWithEmail({ commit }, data: { email: string; password: string }): any {
    return firebase.loginWithEmail(data.email, data.password).then((userCredential) => {
      if (userCredential.user) {
        const user: User = {
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          phoneNumber: userCredential.user.phoneNumber,
          photoURL: userCredential.user.photoURL,
          providerId: userCredential.user.providerId,
          uid: userCredential.user.uid,
        };
        commit("setUser", user);
        return user;
      }
    });
  },
  logout({ commit }): any {
    return firebase.logout().then(() => {
      commit("removeUser");
    });
  },
  initUser({ commit }, firebaseUser): any {
    if (firebaseUser) {
      const user: User = {
        displayName: firebaseUser.displayName,
        email: firebaseUser.email,
        phoneNumber: firebaseUser.phoneNumber,
        photoURL: firebaseUser.photoURL,
        providerId: firebaseUser.providerId,
        uid: firebaseUser.uid,
      };
      commit("setUser", user);
    } else {
      commit("removeUser");
    }
  },
};
