import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  Auth,
  User,
  UserCredential,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import store from "@/store";

interface LocalUser {
  email: string | null;
  photoURL: string | null;
  idToken: string;
  expirationTime: number;
}

class Firebase {
  private readonly auth: Auth;
  private localUser?: LocalUser;

  private firebaseConfig = {
    apiKey: "AIzaSyAjDwb8v1hVA4HWxceNsRR4p_7lTjqTUnU",
    authDomain: "nexttrading-86ced.firebaseapp.com",
    databaseURL: "https://nexttrading-86ced.firebaseio.com",
    projectId: "nexttrading-86ced",
    storageBucket: "nexttrading-86ced.appspot.com",
    messagingSenderId: "468564307065",
    appId: "1:468564307065:web:1b035db8c25525f35438a8",
  };

  constructor() {
    const firebaseApp: FirebaseApp = initializeApp(this.firebaseConfig);

    this.auth = getAuth(firebaseApp);
    this.localUser = this.loadLocalUser() ?? {};
  }

  init() {
    // Initialize store using local user
    store.dispatch("profile/initUser", this.localUser).then();

    // Update on login/logout
    this.auth.onAuthStateChanged((firebaseUser) => {
      // Update store
      store.dispatch("profile/initUser", firebaseUser).then();
      // Update local user
      this.updateLocalUser(firebaseUser);
    });
  }

  getIdToken(): Promise<string | null> {
    return new Promise((resolve) => {
      // Use local user if available (and not expired),
      // this case no need to wait for firebase accounts:lookup request
      const localUser = this.getValidLocalUser();
      if (localUser) {
        resolve(localUser.idToken);
        return;
      }

      this.getFirebaseUser().then((firebaseUser) => {
        if (firebaseUser) {
          firebaseUser.getIdToken().then(
            (idToken) => {
              resolve(idToken);
            },
            () => {
              resolve(null);
            }
          );
        } else {
          resolve(null);
        }
      });
    });
  }

  private loadLocalUser() {
    const userJson = localStorage.getItem("user");
    return userJson !== null ? JSON.parse(userJson) : undefined;
  }

  private updateLocalUser(firebaseUser: User | null) {
    if (firebaseUser !== null) {
      firebaseUser.getIdToken().then((idToken) => {
        this.localUser = {
          email: firebaseUser.email,
          photoURL: firebaseUser.photoURL,
          idToken: idToken,
          // @ts-ignore
          expirationTime: firebaseUser.stsTokenManager.expirationTime,
        };
        localStorage.setItem("user", JSON.stringify(this.localUser));
      });
    } else {
      localStorage.removeItem("user");
    }
  }

  private getFirebaseUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const unsubscribe = this.auth.onAuthStateChanged((firebaseUser) => {
        unsubscribe();
        if (firebaseUser) {
          resolve(firebaseUser);
        } else {
          resolve(null);
        }
      });
    });
  }

  private getValidLocalUser() {
    if (!this.localUser) {
      return;
    }
    const fiveMinutes = 300000;
    const expired = this.localUser.expirationTime - Date.now() < fiveMinutes;
    if (expired) {
      return;
    }
    return this.localUser;
  }

  loginWithGoogle(): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  loginWithFacebook(): Promise<UserCredential> {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(this.auth, provider);
  }

  loginWithEmail(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password).catch((error) => {
      alert(error.message);
      return error;
    });
  }

  logout() {
    return this.auth.signOut();
  }
}

export default new Firebase();
