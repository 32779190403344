import Vue from "vue";
import { DirectiveBinding } from "vue/types/options";

const updateBackground = (el: HTMLElement, binding: DirectiveBinding) => {
  if (binding.value === undefined) {
    el.style.backgroundColor = "";
    return;
  }

  const h = binding.value > 0 ? 140 : 0;
  const value = Math.abs(binding.value);
  const intensity = value * 20;
  const l = 100 - Math.min(intensity, 48) - 2;

  el.style.backgroundColor = "hsl(" + h + ", 75%, " + l + "%)";
};

Vue.directive("arbitrageProfitColored", {
  inserted: (el, binding) => {
    updateBackground(el, binding);
  },
  update: (el, binding) => {
    updateBackground(el, binding);
  },
});
