import {
  AssetClassApi,
  BotApi,
  Configuration,
  ExchangeTransactionApi,
  MarketOhlcvApi,
  NotificationApi,
  SourceApi,
  StrategyApi,
  StrategyFinetuneApi,
  StrategyOptimizerApi,
  StrategyRunApi,
  StrategyRunGroupApi,
  UDFApi,
  MarketApi,
  ExchangeBalanceApi,
  TestApi,
} from "next-trader-api";
import { ConfigMiddleWare } from "@/plugins/api/config-middleware";

const basePath = "";

const apiConfiguration = new Configuration({
  basePath: basePath,
  middleware: [new ConfigMiddleWare()],
});

export const marketApi = new MarketApi(apiConfiguration);
export const notificationApi = new NotificationApi(apiConfiguration);
export const sourceApi = new SourceApi(apiConfiguration);
export const assetClassApi = new AssetClassApi(apiConfiguration);
export const strategyApi = new StrategyApi(apiConfiguration);
export const exchangeBalanceApi = new ExchangeBalanceApi(apiConfiguration);
export const testApi = new TestApi(apiConfiguration);
export const strategyRunApi = new StrategyRunApi(apiConfiguration);
export const strategyRunGroupApi = new StrategyRunGroupApi(apiConfiguration);
export const strategyOptimizerApi = new StrategyOptimizerApi(apiConfiguration);
export const strategyFinetuneApi = new StrategyFinetuneApi(apiConfiguration);
export const botApi = new BotApi(apiConfiguration);
export const exchangeTransactionApi = new ExchangeTransactionApi(apiConfiguration);
export const marketOhlcvApi = new MarketOhlcvApi(apiConfiguration);
export const udfApi = new UDFApi(apiConfiguration);
