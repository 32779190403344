import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import VueMeta from "vue-meta";
import firebase from "@/plugins/firebase";
import ParentRouteComponent from "@/components/ParentRouteComponent.vue";
import UserPage from "@/views/UserPage.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    redirect: "/arbitrage",
  },
  {
    path: "/user",
    name: "user",
    component: UserPage,
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/strategy-run-list",
    component: ParentRouteComponent,
    children: [
      {
        path: "",
        name: "strategyRunList",
        component: () => import(/* webpackChunkName: "StrategyRun" */ "../views/StrategyRunList.vue"),
      },
      {
        path: ":strategyRunId",
        name: "strategyRun",
        component: () => import(/* webpackChunkName: "BackTest" */ "../views/BackTest.vue"),
      },
    ],
  },
  {
    path: "/strategy-run-group-list",
    component: ParentRouteComponent,
    children: [
      {
        path: "",
        name: "strategyRunGroupList",
        component: () => import(/* webpackChunkName: "StrategyRunGroupList" */ "../views/StrategyRunGroupList.vue"),
      },
      {
        path: ":strategyRunGroupId",
        name: "strategyRunGroupIndex",
        component: () => import(/* webpackChunkName: "StrategyRunGroupIndex" */ "../views/StrategyRunGroupIndex.vue"),
      },
    ],
  },
  {
    path: "/strategy-optimizer",
    component: ParentRouteComponent,
    children: [
      {
        path: "",
        name: "strategyOptimizerList",
        component: () => import(/* webpackChunkName: "StrategyOptimizerList" */ "../views/StrategyOptimizerList.vue"),
      },
      {
        path: "new",
        name: "strategyOptimizerNew",
        component: () => import(/* webpackChunkName: "StrategyOptimizerNew" */ "../views/StrategyOptimizerNew.vue"),
      },
      {
        path: "edit/:strategyOptimizeId",
        name: "strategyOptimizerEdit",
        component: () => import(/* webpackChunkName: "StrategyOptimizerNew" */ "../views/StrategyOptimizerNew.vue"),
      },
      {
        path: ":strategyOptimizeId/optimize",
        name: "strategyOptimizer",
        component: () => import(/* webpackChunkName: "StrategyOptimizer" */ "../views/StrategyOptimizer.vue"),
      },
      {
        path: "finetune/:strategyOptimizeResultItemId",
        name: "strategyOptimizerFinetuneById",
        component: () =>
          import(/* webpackChunkName: "StrategyOptimizerFinetune" */ "../views/StrategyOptimizerFinetune.vue"),
      },
    ],
  },
  {
    path: "/strategy-finetune",
    component: ParentRouteComponent,
    children: [
      {
        path: "",
        name: "strategyOptimizerFinetune",
        component: () =>
          import(/* webpackChunkName: "StrategyOptimizerFinetune" */ "../views/StrategyOptimizerFinetune.vue"),
      },
    ],
  },
  {
    path: "/bot-list",
    component: ParentRouteComponent,
    children: [
      {
        path: "",
        name: "botList",
        component: () => import(/* webpackChunkName: "BotList" */ "../views/BotList.vue"),
      },
      {
        path: ":strategyRunId",
        name: "bot",
        component: () => import(/* webpackChunkName: "BackTest" */ "../views/BackTest.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: () => import(/* webpackChunkName: "Test" */ "../views/Test.vue"),
  },
  {
    path: "/arbitrage",
    component: ParentRouteComponent,
    children: [
      {
        path: "",
        name: "arbitrage",
        component: () => import(/* webpackChunkName: "Arbitrage" */ "../views/Arbitrage.vue"),
      },
      {
        path: "history",
        name: "arbitrageHistory",
        component: () =>
          import(/* webpackChunkName: "ArbitrageHistory" */ "../components/ArbitrageHistory/ArbitrageHistory.vue"),
      },
      {
        path: "coin-checker",
        name: "coinChecker",
        component: () => import(/* webpackChunkName: "CoinInfos" */ "../components/CoinChecker/CoinCheckerMain.vue"),
      },
      {
        path: "item/:currencyCode",
        name: "arbitrageItem",
        component: () => import(/* webpackChunkName: "ArbitrageItem" */ "../views/ArbitrageItem.vue"),
      },
      {
        path: "details/:currencyCode",
        name: "arbitrageDetails",
        component: () => import(/* webpackChunkName: "ArbitrageDetails" */ "../views/ArbitrageDetails.vue"),
      },
    ],
  },
  {
    path: "/exchange-balances",
    name: "exchangeBalances",
    component: () => import(/* webpackChunkName: "ExchangeBalances" */ "../views/ExchangeBalances.vue"),
  },
  {
    path: "/transactions",
    name: "transactions",
    component: () => import(/* webpackChunkName: "Transactions" */ "../views/Transactions.vue"),
  },
  {
    path: "/ohlcv-stat",
    name: "ohlcvStat",
    component: () => import(/* webpackChunkName: "OhlcvStat" */ "../views/OhlcvStat.vue"),
  },
  {
    path: "/engine-status",
    name: "engineStatus",
    component: () => import(/* webpackChunkName: "OhlcvStat" */ "../views/EngineStatus.vue"),
  },
  {
    path: "/flight-info",
    name: "flightInfo",
    component: () => import(/* webpackChunkName: "RyanAirSearch" */ "../components/FlightInfo/FlightInfo.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const allowAnonymous = to.matched.every((x) => x.meta.allowAnonymous);
  if (allowAnonymous) {
    next();
  }

  firebase.getIdToken().then((idToken) => {
    if (!idToken) {
      next("/user");
    } else {
      next();
    }
  });
});

export default router;
