
import Component from "vue-class-component";
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  mdiClockCheckOutline,
  mdiRobotLove,
  mdiRocketLaunchOutline,
  mdiLightningBoltCircle,
  mdiAbacus,
  mdiCurrencyUsd,
  mdiLightningBolt,
  mdiAirplaneSearch,
} from "@mdi/js";
import firebase from "@/plugins/firebase";
import BottomNavigatorMobile from "@/components/BottomNavigatorMobile.vue";
import { Watch } from "vue-property-decorator";

const ns = "profile";

@Component({
  components: { BottomNavigatorMobile },
  computed: {
    ...mapGetters(ns, {
      user: "user",
    }),
  },
})
export default class App extends Vue {
  metaInfo = {
    meta: [{ name: "description", content: "Next Trader description" }],
  };

  items: Array<Record<string, unknown>> = [
    // { title: "Transactions", icon: mdiCurrencyUsdCircle, to: "/transactions" },
    // { title: "Ohlcv stat", icon: mdiCurrencyUsdCircle, to: "/ohlcv-stat" },
    {
      title: "Strategy optimizer",
      icon: mdiRocketLaunchOutline,
      to: "/strategy-optimizer",
    },
    {
      title: "Strategy finetuner",
      icon: mdiAbacus,
      to: "/strategy-finetune",
    },
    // {
    //   title: "Backtest multi-market",
    //   icon: mdiViewDashboard,
    //   to: "/strategy-run-group-list"
    // },
    { title: "Backtest", icon: mdiClockCheckOutline, to: "/strategy-run-list" },
    { title: "Bots", icon: mdiRobotLove, to: "/bot-list" },
    //{ title: "Test", icon: mdiImage, to: "/test" }
  ];
  itemsUpdateDone = false;

  drawer = !this.isMobile;

  goUserPage() {
    this.$router.push("/user");
  }

  @Watch("user", { immediate: true })
  onUserChanged() {
    //@ts-ignore
    if (this.user?.email === "baranyai8david@gmail.com") {
      if (!this.itemsUpdateDone) {
        this.items.unshift({ title: "Balances", icon: mdiCurrencyUsd, to: "/exchange-balances" });
        this.items.unshift({ title: "Arbi", icon: mdiLightningBolt, to: "/arbitrage" });
        this.items.push({ title: "Engine status", icon: mdiLightningBoltCircle, to: "/engine-status" });
        this.items.push({ title: "Flight info", icon: mdiAirplaneSearch, to: "/flight-info" });
        this.itemsUpdateDone = true;
      }
    } else {
      this.itemsUpdateDone = false;
    }
  }

  mounted() {
    if (this.isChromeAndroidMobile()) {
      this.$el.querySelector(".v-application--wrap")?.classList.add("chrome-android-mobile");
    }
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  isChromeAndroidMobile() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return (
      userAgent.indexOf("android") !== -1 &&
      userAgent.indexOf("mobile") !== -1 &&
      userAgent.indexOf("chrome") !== -1 &&
      userAgent.indexOf("nexus 5") === -1
    ); //Desktop chrome uses "Nexus 5" device, we don't want to target mobile emulator
  }
}
