// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from "../runtime";
import { BotCreateFromOptimizeResultIdRequest, BotCreateRequest, StrategyRun, StrategyRunListRequest } from "../models";

export interface DeleteAppBotRemoveRequest {
  strategyRunId: number;
}

export interface PostAppBotCreateRequest {
  botCreateRequest?: BotCreateRequest;
}

export interface PostAppBotCreatefromoptimizeresultitemRequest {
  botCreateFromOptimizeResultIdRequest?: BotCreateFromOptimizeResultIdRequest;
}

export interface PostAppBotListRequest {
  strategyRunListRequest?: StrategyRunListRequest;
}

export interface PostAppBotStartRequest {
  strategyRunId: number;
}

export interface PostAppBotStopRequest {
  strategyRunId: number;
}

/**
 * no description
 */
export class BotApi extends BaseAPI {
  /**
   */
  deleteAppBotRemove({ strategyRunId }: DeleteAppBotRemoveRequest): Observable<void>;
  deleteAppBotRemove(
    { strategyRunId }: DeleteAppBotRemoveRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  deleteAppBotRemove(
    { strategyRunId }: DeleteAppBotRemoveRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "deleteAppBotRemove");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/bot/remove/{strategyRunId}".replace("{strategyRunId}", encodeURI(strategyRunId)),
        method: "DELETE",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppBotCreate({ botCreateRequest }: PostAppBotCreateRequest): Observable<void>;
  postAppBotCreate(
    { botCreateRequest }: PostAppBotCreateRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppBotCreate(
    { botCreateRequest }: PostAppBotCreateRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/bot/create",
        method: "POST",
        headers,
        body: botCreateRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppBotCreatefromoptimizeresultitem({
    botCreateFromOptimizeResultIdRequest,
  }: PostAppBotCreatefromoptimizeresultitemRequest): Observable<void>;
  postAppBotCreatefromoptimizeresultitem(
    { botCreateFromOptimizeResultIdRequest }: PostAppBotCreatefromoptimizeresultitemRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppBotCreatefromoptimizeresultitem(
    { botCreateFromOptimizeResultIdRequest }: PostAppBotCreatefromoptimizeresultitemRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/bot/createFromOptimizeResultItem",
        method: "POST",
        headers,
        body: botCreateFromOptimizeResultIdRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppBotList({ strategyRunListRequest }: PostAppBotListRequest): Observable<Array<StrategyRun>>;
  postAppBotList(
    { strategyRunListRequest }: PostAppBotListRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<StrategyRun>>>;
  postAppBotList(
    { strategyRunListRequest }: PostAppBotListRequest,
    opts?: OperationOpts
  ): Observable<Array<StrategyRun> | RawAjaxResponse<Array<StrategyRun>>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<StrategyRun>>(
      {
        url: "/api/bot",
        method: "POST",
        headers,
        body: strategyRunListRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppBotStart({ strategyRunId }: PostAppBotStartRequest): Observable<void>;
  postAppBotStart(
    { strategyRunId }: PostAppBotStartRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppBotStart(
    { strategyRunId }: PostAppBotStartRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "postAppBotStart");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/bot/{strategyRunId}/start".replace("{strategyRunId}", encodeURI(strategyRunId)),
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppBotStop({ strategyRunId }: PostAppBotStopRequest): Observable<void>;
  postAppBotStop(
    { strategyRunId }: PostAppBotStopRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppBotStop(
    { strategyRunId }: PostAppBotStopRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "postAppBotStop");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/bot/{strategyRunId}/stop".replace("{strategyRunId}", encodeURI(strategyRunId)),
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }
}
