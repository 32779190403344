// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from "../runtime";
import {
  GetIndicatorConfigsResult,
  InlineResponse200,
  SaveSupportResistancePricesRequest,
  StrategyRun,
  StrategyRunExecuteRequest,
  StrategyRunGetOneRequest,
  StrategyRunListRequest,
  SupportResistancePrice,
} from "../models";

export interface GetAppStrategyrunGethodlchartdataRequest {
  strategyRunId: string;
}

export interface GetAppStrategyrunGetindicatorconfigsRequest {
  strategyRunId: string;
}

export interface GetAppStrategyrunGetsupportresistancepricesRequest {
  strategyRunId: string;
}

export interface PostAppStrategyrunCreateRequest {
  strategyRunExecuteRequest?: StrategyRunExecuteRequest;
}

export interface PostAppStrategyrunGetoneRequest {
  strategyRunId: string;
  strategyRunGetOneRequest?: StrategyRunGetOneRequest;
}

export interface PostAppStrategyrunListRequest {
  strategyRunListRequest?: StrategyRunListRequest;
}

export interface PostAppStrategyrunSavesupportresistancepricesRequest {
  strategyRunId: string;
  saveSupportResistancePricesRequest?: SaveSupportResistancePricesRequest;
}

/**
 * no description
 */
export class StrategyRunApi extends BaseAPI {
  /**
   */
  getAppStrategyrunGethodlchartdata({ strategyRunId }: GetAppStrategyrunGethodlchartdataRequest): Observable<void>;
  getAppStrategyrunGethodlchartdata(
    { strategyRunId }: GetAppStrategyrunGethodlchartdataRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  getAppStrategyrunGethodlchartdata(
    { strategyRunId }: GetAppStrategyrunGethodlchartdataRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "getAppStrategyrunGethodlchartdata");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategy-run/hodl-chart-data/{strategyRunId}".replace("{strategyRunId}", encodeURI(strategyRunId)),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyrunGetindicatorconfigs({
    strategyRunId,
  }: GetAppStrategyrunGetindicatorconfigsRequest): Observable<GetIndicatorConfigsResult>;
  getAppStrategyrunGetindicatorconfigs(
    { strategyRunId }: GetAppStrategyrunGetindicatorconfigsRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<GetIndicatorConfigsResult>>;
  getAppStrategyrunGetindicatorconfigs(
    { strategyRunId }: GetAppStrategyrunGetindicatorconfigsRequest,
    opts?: OperationOpts
  ): Observable<GetIndicatorConfigsResult | RawAjaxResponse<GetIndicatorConfigsResult>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "getAppStrategyrunGetindicatorconfigs");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<GetIndicatorConfigsResult>(
      {
        url: "/api/strategy-run/getIndicatorConfigs/{strategyRunId}".replace(
          "{strategyRunId}",
          encodeURI(strategyRunId)
        ),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyrunGetsupportresistanceprices({
    strategyRunId,
  }: GetAppStrategyrunGetsupportresistancepricesRequest): Observable<Array<SupportResistancePrice>>;
  getAppStrategyrunGetsupportresistanceprices(
    { strategyRunId }: GetAppStrategyrunGetsupportresistancepricesRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<SupportResistancePrice>>>;
  getAppStrategyrunGetsupportresistanceprices(
    { strategyRunId }: GetAppStrategyrunGetsupportresistancepricesRequest,
    opts?: OperationOpts
  ): Observable<Array<SupportResistancePrice> | RawAjaxResponse<Array<SupportResistancePrice>>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "getAppStrategyrunGetsupportresistanceprices");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<SupportResistancePrice>>(
      {
        url: "/api/strategy-run/getSupportResistancePrices/{strategyRunId}".replace(
          "{strategyRunId}",
          encodeURI(strategyRunId)
        ),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyrunCreate({
    strategyRunExecuteRequest,
  }: PostAppStrategyrunCreateRequest): Observable<InlineResponse200>;
  postAppStrategyrunCreate(
    { strategyRunExecuteRequest }: PostAppStrategyrunCreateRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<InlineResponse200>>;
  postAppStrategyrunCreate(
    { strategyRunExecuteRequest }: PostAppStrategyrunCreateRequest,
    opts?: OperationOpts
  ): Observable<InlineResponse200 | RawAjaxResponse<InlineResponse200>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<InlineResponse200>(
      {
        url: "/api/strategy-run/create",
        method: "POST",
        headers,
        body: strategyRunExecuteRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyrunGetone({
    strategyRunId,
    strategyRunGetOneRequest,
  }: PostAppStrategyrunGetoneRequest): Observable<StrategyRun>;
  postAppStrategyrunGetone(
    { strategyRunId, strategyRunGetOneRequest }: PostAppStrategyrunGetoneRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StrategyRun>>;
  postAppStrategyrunGetone(
    { strategyRunId, strategyRunGetOneRequest }: PostAppStrategyrunGetoneRequest,
    opts?: OperationOpts
  ): Observable<StrategyRun | RawAjaxResponse<StrategyRun>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "postAppStrategyrunGetone");

    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StrategyRun>(
      {
        url: "/api/strategy-run/{strategyRunId}".replace("{strategyRunId}", encodeURI(strategyRunId)),
        method: "POST",
        headers,
        body: strategyRunGetOneRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyrunList({ strategyRunListRequest }: PostAppStrategyrunListRequest): Observable<Array<StrategyRun>>;
  postAppStrategyrunList(
    { strategyRunListRequest }: PostAppStrategyrunListRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<StrategyRun>>>;
  postAppStrategyrunList(
    { strategyRunListRequest }: PostAppStrategyrunListRequest,
    opts?: OperationOpts
  ): Observable<Array<StrategyRun> | RawAjaxResponse<Array<StrategyRun>>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<StrategyRun>>(
      {
        url: "/api/strategy-run",
        method: "POST",
        headers,
        body: strategyRunListRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyrunSavesupportresistanceprices({
    strategyRunId,
    saveSupportResistancePricesRequest,
  }: PostAppStrategyrunSavesupportresistancepricesRequest): Observable<void>;
  postAppStrategyrunSavesupportresistanceprices(
    { strategyRunId, saveSupportResistancePricesRequest }: PostAppStrategyrunSavesupportresistancepricesRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyrunSavesupportresistanceprices(
    { strategyRunId, saveSupportResistancePricesRequest }: PostAppStrategyrunSavesupportresistancepricesRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyRunId, "strategyRunId", "postAppStrategyrunSavesupportresistanceprices");

    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategy-run/saveSupportResistancePrices/{strategyRunId}".replace(
          "{strategyRunId}",
          encodeURI(strategyRunId)
        ),
        method: "POST",
        headers,
        body: saveSupportResistancePricesRequest,
      },
      opts?.responseOpts
    );
  }
}
