import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/store/types";
import { profile } from "@/store/profile";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store: StoreOptions<RootState> = {
  modules: {
    profile,
  },
  strict: debug,
};

export default new Vuex.Store<RootState>(store);
