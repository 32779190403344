// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse } from "../runtime";
import { AssetClass } from "../models";

/**
 * no description
 */
export class AssetClassApi extends BaseAPI {
  /**
   */
  getAppAssetclassGetall(): Observable<Array<AssetClass>>;
  getAppAssetclassGetall(opts?: OperationOpts): Observable<RawAjaxResponse<Array<AssetClass>>>;
  getAppAssetclassGetall(opts?: OperationOpts): Observable<Array<AssetClass> | RawAjaxResponse<Array<AssetClass>>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<AssetClass>>(
      {
        url: "/api/assetClass/",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }
}
