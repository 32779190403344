// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse } from "../runtime";

export interface GetAppUdfHistoryRequest {
  symbol?: string;
  resolution?: string;
  from?: string;
  to?: string;
}

/**
 * no description
 */
export class UDFApi extends BaseAPI {
  /**
   */
  getAppUdfHistory({ symbol, resolution, from, to }: GetAppUdfHistoryRequest): Observable<void>;
  getAppUdfHistory(
    { symbol, resolution, from, to }: GetAppUdfHistoryRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  getAppUdfHistory(
    { symbol, resolution, from, to }: GetAppUdfHistoryRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    const query: HttpQuery = {};

    if (symbol != null) {
      query["symbol"] = symbol;
    }
    if (resolution != null) {
      query["resolution"] = resolution;
    }
    if (from != null) {
      query["from"] = from;
    }
    if (to != null) {
      query["to"] = to;
    }

    return this.request<void>(
      {
        url: "/api/udf/history",
        method: "GET",
        headers,
        query,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppUdfSearch(): Observable<void>;
  getAppUdfSearch(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  getAppUdfSearch(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/udf/search",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppUdfSymbols(): Observable<void>;
  getAppUdfSymbols(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  getAppUdfSymbols(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/udf/symbols",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppUdfTime(): Observable<void>;
  getAppUdfTime(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  getAppUdfTime(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/udf/time",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }
}
