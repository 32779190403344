import { Middleware, RequestArgs, ResponseArgs } from "api/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import firebase from "@/plugins/firebase";
import { Observable, defer } from "rxjs";
import { map } from "rxjs/operators";

NProgress.configure({ trickleSpeed: 50 });

export class ConfigMiddleWare implements Middleware {
  pre(request: RequestArgs): RequestArgs {
    //NProgress.start(); //TODO switch ON
    return request;
  }

  post(response: ResponseArgs): ResponseArgs {
    //NProgress.done(); //TODO switch ON
    return response;
  }

  preAsync(request: RequestArgs): Observable<RequestArgs> {
    return defer(() => firebase.getIdToken()).pipe(
      map((idToken) => {
        if (idToken !== null) {
          request.headers = { ...request.headers, "X-AUTH-TOKEN": idToken };
        }
        return request;
      })
    );
  }
}
