
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ECharts } from "echarts";
import * as echarts from "echarts/core";
import { ECBasicOption } from "echarts/types/dist/shared";
import { LocaleOption } from "echarts/types/src/core/locale";
import { RendererType } from "echarts/types/src/util/types";
declare global {
  interface Window {
    attachEvent(type: string, callback: any): void;
    detachEvent(type: string, callback: any): void;
  }
}
declare type EChartsInitOpts = {
  locale?: string | LocaleOption;
  renderer?: RendererType;
  devicePixelRatio?: number;
  useDirtyRect?: boolean;
  ssr?: boolean;
  width?: number;
  height?: number;
};
@Component
export default class VueEchartsComponent extends Vue {
  private echartsInstance!: echarts.EChartsType;
  @Prop() private ei!: ECharts;
  @Prop() private option!: ECBasicOption;
  @Prop() private theme?: string;
  @Prop() private initOption?: EChartsInitOpts;
  @Watch("option", { deep: true })
  private optionChanged(v: ECBasicOption, oldV: ECBasicOption) {
    this.renderEcharts();
  }
  private resizeEventHandler(): void {
    this.echartsInstance.resize();
  }
  private Init() {
    if (this.option) {
      this.echartsInstance = echarts.init(this.$refs.ele as HTMLDivElement, this.theme, this.initOption);
      this.$emit("update:ei", this.echartsInstance);
      if (window.addEventListener) {
        window.addEventListener("resize", this.resizeEventHandler, false);
      } else if (window.attachEvent) {
        const that = this;
        window.attachEvent("resize", that.resizeEventHandler);
      }
    }
  }
  private renderEcharts() {
    if (this.option && this.echartsInstance) {
      this.echartsInstance.setOption(this.option);
      this.$emit("instance", this.echartsInstance);
    }
  }
  private mounted() {
    this.Init();
    this.renderEcharts();
    setTimeout(() => {
      this.echartsInstance.resize();
    }, 1);
  }
  private destoryed() {
    this.$emit("instance", null);
    if (window.removeEventListener) {
      window.removeEventListener("resize", this.resizeEventHandler, false);
    } else if (window.detachEvent) {
      const that = this;
      window.detachEvent("resize", that.resizeEventHandler);
    }
    this.echartsInstance.dispose();
  }
}
