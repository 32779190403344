import Vue from "vue";

const onWheel = (evt: Event) => {
  const event = evt as WheelEvent;

  if (event.ctrlKey) {
    event.preventDefault();
  }
};

Vue.directive("preventChromeZooming", {
  bind: (el) => {
    el.addEventListener("wheel", onWheel, { passive: false });
  },
  unbind: (el) => {
    el.removeEventListener("wheel", onWheel);
  },
});
