// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from "../runtime";
import {
  GetStrategyResponse,
  HodlRequest,
  OptimizeCreateRequest,
  StrategyOptimize,
  StrategyOptimizeResult,
  StrategyOptimizeResultItem,
} from "../models";

export interface DeleteAppStrategyoptimizerRemoveRequest {
  strategyOptimizeId: number;
}

export interface GetAppStrategyoptimizerGetoneRequest {
  strategyOptimizeId: number;
}

export interface GetAppStrategyoptimizerGetresultitemRequest {
  strategyOptimizeResultItemId: number;
}

export interface GetAppStrategyoptimizerGetresultitemsRequest {
  optimizeResultId: number;
}

export interface GetAppStrategyoptimizerGetresultsRequest {
  strategyOptimizeId: number;
}

export interface PostAppStrategyoptimizerCreateRequest {
  optimizeCreateRequest?: OptimizeCreateRequest;
}

export interface PostAppStrategyoptimizerHodlRequest {
  hodlRequest?: HodlRequest;
}

export interface PostAppStrategyoptimizerOptimizeRequest {
  strategyOptimizeId: number;
}

export interface PostAppStrategyoptimizerRestartRequest {
  strategyOptimizeId: number;
}

export interface PostAppStrategyoptimizerStopRequest {
  strategyOptimizeId: number;
}

/**
 * no description
 */
export class StrategyOptimizerApi extends BaseAPI {
  /**
   */
  deleteAppStrategyoptimizerRemove({ strategyOptimizeId }: DeleteAppStrategyoptimizerRemoveRequest): Observable<void>;
  deleteAppStrategyoptimizerRemove(
    { strategyOptimizeId }: DeleteAppStrategyoptimizerRemoveRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  deleteAppStrategyoptimizerRemove(
    { strategyOptimizeId }: DeleteAppStrategyoptimizerRemoveRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyOptimizeId, "strategyOptimizeId", "deleteAppStrategyoptimizerRemove");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/remove/{strategyOptimizeId}".replace(
          "{strategyOptimizeId}",
          encodeURI(strategyOptimizeId)
        ),
        method: "DELETE",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyoptimizerGetall(): Observable<Array<StrategyOptimize>>;
  getAppStrategyoptimizerGetall(opts?: OperationOpts): Observable<RawAjaxResponse<Array<StrategyOptimize>>>;
  getAppStrategyoptimizerGetall(
    opts?: OperationOpts
  ): Observable<Array<StrategyOptimize> | RawAjaxResponse<Array<StrategyOptimize>>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<StrategyOptimize>>(
      {
        url: "/api/strategyOptimizer/getAll",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyoptimizerGetone({
    strategyOptimizeId,
  }: GetAppStrategyoptimizerGetoneRequest): Observable<StrategyOptimize>;
  getAppStrategyoptimizerGetone(
    { strategyOptimizeId }: GetAppStrategyoptimizerGetoneRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StrategyOptimize>>;
  getAppStrategyoptimizerGetone(
    { strategyOptimizeId }: GetAppStrategyoptimizerGetoneRequest,
    opts?: OperationOpts
  ): Observable<StrategyOptimize | RawAjaxResponse<StrategyOptimize>> {
    throwIfNullOrUndefined(strategyOptimizeId, "strategyOptimizeId", "getAppStrategyoptimizerGetone");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StrategyOptimize>(
      {
        url: "/api/strategyOptimizer/getOne/{strategyOptimizeId}".replace(
          "{strategyOptimizeId}",
          encodeURI(strategyOptimizeId)
        ),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyoptimizerGetresultitem({
    strategyOptimizeResultItemId,
  }: GetAppStrategyoptimizerGetresultitemRequest): Observable<StrategyOptimizeResultItem>;
  getAppStrategyoptimizerGetresultitem(
    { strategyOptimizeResultItemId }: GetAppStrategyoptimizerGetresultitemRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StrategyOptimizeResultItem>>;
  getAppStrategyoptimizerGetresultitem(
    { strategyOptimizeResultItemId }: GetAppStrategyoptimizerGetresultitemRequest,
    opts?: OperationOpts
  ): Observable<StrategyOptimizeResultItem | RawAjaxResponse<StrategyOptimizeResultItem>> {
    throwIfNullOrUndefined(
      strategyOptimizeResultItemId,
      "strategyOptimizeResultItemId",
      "getAppStrategyoptimizerGetresultitem"
    );

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StrategyOptimizeResultItem>(
      {
        url: "/api/strategyOptimizer/getResultItem/{strategyOptimizeResultItemId}".replace(
          "{strategyOptimizeResultItemId}",
          encodeURI(strategyOptimizeResultItemId)
        ),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyoptimizerGetresultitems({
    optimizeResultId,
  }: GetAppStrategyoptimizerGetresultitemsRequest): Observable<Array<StrategyOptimizeResultItem>>;
  getAppStrategyoptimizerGetresultitems(
    { optimizeResultId }: GetAppStrategyoptimizerGetresultitemsRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<StrategyOptimizeResultItem>>>;
  getAppStrategyoptimizerGetresultitems(
    { optimizeResultId }: GetAppStrategyoptimizerGetresultitemsRequest,
    opts?: OperationOpts
  ): Observable<Array<StrategyOptimizeResultItem> | RawAjaxResponse<Array<StrategyOptimizeResultItem>>> {
    throwIfNullOrUndefined(optimizeResultId, "optimizeResultId", "getAppStrategyoptimizerGetresultitems");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<StrategyOptimizeResultItem>>(
      {
        url: "/api/strategyOptimizer/getResultItems/{optimizeResultId}".replace(
          "{optimizeResultId}",
          encodeURI(optimizeResultId)
        ),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyoptimizerGetresults({
    strategyOptimizeId,
  }: GetAppStrategyoptimizerGetresultsRequest): Observable<Array<StrategyOptimizeResult>>;
  getAppStrategyoptimizerGetresults(
    { strategyOptimizeId }: GetAppStrategyoptimizerGetresultsRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<StrategyOptimizeResult>>>;
  getAppStrategyoptimizerGetresults(
    { strategyOptimizeId }: GetAppStrategyoptimizerGetresultsRequest,
    opts?: OperationOpts
  ): Observable<Array<StrategyOptimizeResult> | RawAjaxResponse<Array<StrategyOptimizeResult>>> {
    throwIfNullOrUndefined(strategyOptimizeId, "strategyOptimizeId", "getAppStrategyoptimizerGetresults");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<StrategyOptimizeResult>>(
      {
        url: "/api/strategyOptimizer/getResults/{strategyOptimizeId}".replace(
          "{strategyOptimizeId}",
          encodeURI(strategyOptimizeId)
        ),
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppStrategyoptimizerGetstrategies(): Observable<GetStrategyResponse>;
  getAppStrategyoptimizerGetstrategies(opts?: OperationOpts): Observable<RawAjaxResponse<GetStrategyResponse>>;
  getAppStrategyoptimizerGetstrategies(
    opts?: OperationOpts
  ): Observable<GetStrategyResponse | RawAjaxResponse<GetStrategyResponse>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<GetStrategyResponse>(
      {
        url: "/api/strategyOptimizer/getStrategies",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyoptimizerCreate({ optimizeCreateRequest }: PostAppStrategyoptimizerCreateRequest): Observable<void>;
  postAppStrategyoptimizerCreate(
    { optimizeCreateRequest }: PostAppStrategyoptimizerCreateRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyoptimizerCreate(
    { optimizeCreateRequest }: PostAppStrategyoptimizerCreateRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/create",
        method: "POST",
        headers,
        body: optimizeCreateRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyoptimizerHodl({ hodlRequest }: PostAppStrategyoptimizerHodlRequest): Observable<void>;
  postAppStrategyoptimizerHodl(
    { hodlRequest }: PostAppStrategyoptimizerHodlRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyoptimizerHodl(
    { hodlRequest }: PostAppStrategyoptimizerHodlRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/hodl",
        method: "POST",
        headers,
        body: hodlRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyoptimizerOptimize({ strategyOptimizeId }: PostAppStrategyoptimizerOptimizeRequest): Observable<void>;
  postAppStrategyoptimizerOptimize(
    { strategyOptimizeId }: PostAppStrategyoptimizerOptimizeRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyoptimizerOptimize(
    { strategyOptimizeId }: PostAppStrategyoptimizerOptimizeRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyOptimizeId, "strategyOptimizeId", "postAppStrategyoptimizerOptimize");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/optimize/{strategyOptimizeId}".replace(
          "{strategyOptimizeId}",
          encodeURI(strategyOptimizeId)
        ),
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   * Not used.
   */
  postAppStrategyoptimizerOptimizeentriespersist(): Observable<void>;
  postAppStrategyoptimizerOptimizeentriespersist(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyoptimizerOptimizeentriespersist(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/optimize-entries-persist",
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyoptimizerRestart({ strategyOptimizeId }: PostAppStrategyoptimizerRestartRequest): Observable<void>;
  postAppStrategyoptimizerRestart(
    { strategyOptimizeId }: PostAppStrategyoptimizerRestartRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyoptimizerRestart(
    { strategyOptimizeId }: PostAppStrategyoptimizerRestartRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyOptimizeId, "strategyOptimizeId", "postAppStrategyoptimizerRestart");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/restart/{strategyOptimizeId}".replace(
          "{strategyOptimizeId}",
          encodeURI(strategyOptimizeId)
        ),
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyoptimizerStop({ strategyOptimizeId }: PostAppStrategyoptimizerStopRequest): Observable<void>;
  postAppStrategyoptimizerStop(
    { strategyOptimizeId }: PostAppStrategyoptimizerStopRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyoptimizerStop(
    { strategyOptimizeId }: PostAppStrategyoptimizerStopRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    throwIfNullOrUndefined(strategyOptimizeId, "strategyOptimizeId", "postAppStrategyoptimizerStop");

    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyOptimizer/stop/{strategyOptimizeId}".replace(
          "{strategyOptimizeId}",
          encodeURI(strategyOptimizeId)
        ),
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }
}
