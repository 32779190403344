import Vue from "vue";

Vue.directive("colorUpdate", {
  update: (el, binding) => {
    if (binding.value !== binding.oldValue) {
      const color = binding.value > binding.oldValue ? "#98dd98" : "#dd9898";
      el.style.transition = "none";
      el.style.backgroundColor = color;
      setTimeout(() => {
        el.style.transition = "background-color 0.8s ease";
        el.style.backgroundColor = "inherit";
      });
    }
  },
});
