import { notificationApi } from "@/plugins/api";
import { firstValueFrom } from "rxjs";

export class PushRegister {
  private static publicKey = "BFfLAqp3g9WY88gsjROq-fnUYsvzbD8nQyIA_BTWrMw0izxBu7Oy0fwwPtr4gF4Ai-q9_N0UxkT8RZQ_Jqwy22s";
  private static registration: ServiceWorkerRegistration;

  static async execute(registration: ServiceWorkerRegistration) {
    this.registration = registration;
  }

  static async subscribe() {
    await this.askPermission();

    const subscribeOptions = {
      userVisibleOnly: true,
      applicationServerKey: this.urlB64ToUint8Array(this.publicKey),
    };

    const pushSubscription = await this.registration.pushManager.subscribe(subscribeOptions);
    console.log("Subscribed", JSON.stringify(pushSubscription));

    return await this.saveSubscribe(pushSubscription);
  }

  static async unsubscribe() {
    const pushSubscription = await this.getPushSubscription();
    if (pushSubscription === null) {
      return;
    }

    await pushSubscription.unsubscribe();
    console.log("Unsubscribed");

    return await this.saveUnsubscribe(pushSubscription);
  }

  static async getPushSubscription() {
    return await this.registration.pushManager.getSubscription();
  }

  private static async askPermission() {
    const permissionResult = await Notification.requestPermission();

    if (permissionResult !== "granted") {
      throw new Error("permissionNotGranted");
    }
  }

  private static async saveSubscribe(pushSubscription: PushSubscription | null) {
    let result;
    try {
      result = await firstValueFrom(
        notificationApi.postAppNotificationSubscribe({
          notificationSubscribeRequest: { pushSubscription: JSON.stringify(pushSubscription) },
        })
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (result !== true) {
      throw new Error("Your subscription might not saved, please try again!");
    }
  }

  private static async saveUnsubscribe(pushSubscription: PushSubscription) {
    return await firstValueFrom(
      notificationApi.postAppNotificationUnsubscribe({
        notificationSubscribeRequest: { pushSubscription: JSON.stringify(pushSubscription) },
      })
    );
  }

  private static urlB64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
}
