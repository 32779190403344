// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse } from "../runtime";
import { Market, MarketQueryRequest, MarketUpdatePriceRequest } from "../models";

export interface PostAppMarketQueryRequest {
  marketQueryRequest?: MarketQueryRequest;
}

export interface PostAppMarketUpdatepriceRequest {
  marketUpdatePriceRequest?: MarketUpdatePriceRequest;
}

/**
 * no description
 */
export class MarketApi extends BaseAPI {
  /**
   */
  getAppMarketGetall(): Observable<Array<Market>>;
  getAppMarketGetall(opts?: OperationOpts): Observable<RawAjaxResponse<Array<Market>>>;
  getAppMarketGetall(opts?: OperationOpts): Observable<Array<Market> | RawAjaxResponse<Array<Market>>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<Market>>(
      {
        url: "/api/market/",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  getAppMarketGetmarketids(): Observable<any>;
  getAppMarketGetmarketids(opts?: OperationOpts): Observable<RawAjaxResponse<any>>;
  getAppMarketGetmarketids(opts?: OperationOpts): Observable<any | RawAjaxResponse<any>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<any>(
      {
        url: "/api/market/marketIds",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppMarketQuery({ marketQueryRequest }: PostAppMarketQueryRequest): Observable<Array<Market>>;
  postAppMarketQuery(
    { marketQueryRequest }: PostAppMarketQueryRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<Market>>>;
  postAppMarketQuery(
    { marketQueryRequest }: PostAppMarketQueryRequest,
    opts?: OperationOpts
  ): Observable<Array<Market> | RawAjaxResponse<Array<Market>>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<Array<Market>>(
      {
        url: "/api/market/query",
        method: "POST",
        headers,
        body: marketQueryRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppMarketUpdateprice({ marketUpdatePriceRequest }: PostAppMarketUpdatepriceRequest): Observable<void>;
  postAppMarketUpdateprice(
    { marketUpdatePriceRequest }: PostAppMarketUpdatepriceRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppMarketUpdateprice(
    { marketUpdatePriceRequest }: PostAppMarketUpdatepriceRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/market/updatePrice",
        method: "POST",
        headers,
        body: marketUpdatePriceRequest,
      },
      opts?.responseOpts
    );
  }
}
