import Vue from "vue";

Vue.directive("nx-sticky", {
  bind: (el) => {
    el.style.top = "0";
    el.style.position = "sticky";
    el.style.backgroundColor = "white";
    el.style.zIndex = "1";
    el.style.borderBottom = "1px solid #ccc";
  },
});
