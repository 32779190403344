import Vue from "vue";
import dayjs from "dayjs";

const actualTimezoneOffset = new Date().getTimezoneOffset();

//
// Timezone offset is calculated based on the time value passed to this filter,
// no matter what the current time/DST is.
// This works because UTC plugin is installed for dayjs.
//
Vue.filter("formatDate", function (value: string) {
  if (value) {
    return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
  }
});

//
// Timezone offset is calculated based on the current time/DST,
// no matter what the time value passed to this filter.
//
Vue.filter("formatDateFixOffset", function (value: string) {
  if (value) {
    return dayjs(value).utcOffset(-actualTimezoneOffset).format("YYYY-MM-DD HH:mm:ss");
  }
});

Vue.filter("formatDateNoTime", function (value: string) {
  if (value) {
    return dayjs(value).format("YYYY-MM-DD");
  }
});

Vue.filter("formatDateNoTimeFixOffset", function (value: string) {
  if (value) {
    return dayjs(value).utcOffset(-actualTimezoneOffset).format("YYYY-MM-DD");
  }
});

Vue.filter("formatDateFromNow", function (value: string) {
  if (value) {
    return dayjs(value).fromNow();
  }
});
