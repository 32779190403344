import { MutationTree } from "vuex";
import { ProfileState } from "./types";
import { UserInfo } from "firebase";

export const mutations: MutationTree<ProfileState> = {
  setUser(state, user: UserInfo) {
    state.user = user;
  },
  removeUser(state) {
    state.user = undefined;
  },
};
