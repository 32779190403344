// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse } from "../runtime";

export interface PostAppTestGetcandlechartdataRequest {
  body?: any | null;
}

export interface PostAppTestTestRequest {
  body?: any | null;
}

/**
 * no description
 */
export class TestApi extends BaseAPI {
  /**
   */
  getAppTestTradefileimport(): Observable<void>;
  getAppTestTradefileimport(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  getAppTestTradefileimport(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/test/tradeFileImport",
        method: "GET",
        headers,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppTestGetcandlechartdata({ body }: PostAppTestGetcandlechartdataRequest): Observable<void>;
  postAppTestGetcandlechartdata(
    { body }: PostAppTestGetcandlechartdataRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppTestGetcandlechartdata(
    { body }: PostAppTestGetcandlechartdataRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/test/getCandleChartData",
        method: "POST",
        headers,
        body: body as any,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppTestTest({ body }: PostAppTestTestRequest): Observable<void>;
  postAppTestTest({ body }: PostAppTestTestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  postAppTestTest({ body }: PostAppTestTestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/test",
        method: "POST",
        headers,
        body: body as any,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppTestTradefileimport(): Observable<void>;
  postAppTestTradefileimport(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>;
  postAppTestTradefileimport(opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/test/tradeFileImport",
        method: "POST",
        headers,
      },
      opts?.responseOpts
    );
  }
}
