// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse } from "../runtime";
import { NotificationSubscribeRequest } from "../models";

export interface PostAppNotificationSubscribeRequest {
  notificationSubscribeRequest?: NotificationSubscribeRequest;
}

export interface PostAppNotificationUnsubscribeRequest {
  notificationSubscribeRequest?: NotificationSubscribeRequest;
}

/**
 * no description
 */
export class NotificationApi extends BaseAPI {
  /**
   */
  postAppNotificationSubscribe({
    notificationSubscribeRequest,
  }: PostAppNotificationSubscribeRequest): Observable<boolean>;
  postAppNotificationSubscribe(
    { notificationSubscribeRequest }: PostAppNotificationSubscribeRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<boolean>>;
  postAppNotificationSubscribe(
    { notificationSubscribeRequest }: PostAppNotificationSubscribeRequest,
    opts?: OperationOpts
  ): Observable<boolean | RawAjaxResponse<boolean>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<boolean>(
      {
        url: "/api/notification/subscribe",
        method: "POST",
        headers,
        body: notificationSubscribeRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppNotificationUnsubscribe({
    notificationSubscribeRequest,
  }: PostAppNotificationUnsubscribeRequest): Observable<boolean>;
  postAppNotificationUnsubscribe(
    { notificationSubscribeRequest }: PostAppNotificationUnsubscribeRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<boolean>>;
  postAppNotificationUnsubscribe(
    { notificationSubscribeRequest }: PostAppNotificationUnsubscribeRequest,
    opts?: OperationOpts
  ): Observable<boolean | RawAjaxResponse<boolean>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<boolean>(
      {
        url: "/api/notification/unsubscribe",
        method: "POST",
        headers,
        body: notificationSubscribeRequest,
      },
      opts?.responseOpts
    );
  }
}
