// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, HttpQuery, OperationOpts, RawAjaxResponse } from "../runtime";
import { MarketOhlcvStatDTO } from "../models";

export interface GetAppMarketohlcvStatRequest {
  startDate?: string;
}

/**
 * no description
 */
export class MarketOhlcvApi extends BaseAPI {
  /**
   */
  getAppMarketohlcvStat({ startDate }: GetAppMarketohlcvStatRequest): Observable<Array<MarketOhlcvStatDTO>>;
  getAppMarketohlcvStat(
    { startDate }: GetAppMarketohlcvStatRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<Array<MarketOhlcvStatDTO>>>;
  getAppMarketohlcvStat(
    { startDate }: GetAppMarketohlcvStatRequest,
    opts?: OperationOpts
  ): Observable<Array<MarketOhlcvStatDTO> | RawAjaxResponse<Array<MarketOhlcvStatDTO>>> {
    const headers: HttpHeaders = {
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    const query: HttpQuery = {};

    if (startDate != null) {
      query["startDate"] = startDate;
    }

    return this.request<Array<MarketOhlcvStatDTO>>(
      {
        url: "/api/marketOhlcv/stat",
        method: "GET",
        headers,
        query,
      },
      opts?.responseOpts
    );
  }
}
