
import Component from "vue-class-component";
import Vue from "vue";
import {
  mdiAccount,
  mdiAirplaneSearch,
  mdiChartTimelineVariant,
  mdiCurrencyUsd,
  mdiLightningBolt,
  mdiRobotLove,
} from "@mdi/js";
import firebase from "@/plugins/firebase";
import { mapGetters } from "vuex";
import { Watch } from "vue-property-decorator";

const ns = "profile";

@Component({
  computed: {
    ...mapGetters(ns, {
      user: "user",
    }),
  },
})
export default class BottomNavigatorMobile extends Vue {
  icons = {
    mdiCurrencyUsd: mdiCurrencyUsd,
    mdiChartTimelineVariant: mdiChartTimelineVariant,
    mdiRobotLove: mdiRobotLove,
    mdiAccount: mdiAccount,
    mdiLightningBolt: mdiLightningBolt,
    mdiAirplaneSearch: mdiAirplaneSearch,
  };

  items: Array<{ to: string; label: string; icon: string }> = [
    //{ to: "/tv-chart", label: "Chart", icon: this.icons.mdiChartTimelineVariant },
    { to: "/bot-list", label: "Bots", icon: this.icons.mdiRobotLove },
    { to: "/user", label: "Profile", icon: this.icons.mdiAccount },
  ];
  itemsUpdateDone = false;

  @Watch("user", { immediate: true })
  onUserChanged() {
    //@ts-ignore
    if (this.user?.email === "baranyai8david@gmail.com") {
      if (!this.itemsUpdateDone) {
        this.items.unshift({ to: "/exchange-balances", label: "Balances", icon: this.icons.mdiCurrencyUsd });
        this.items.unshift({ to: "/arbitrage", label: "Arbi", icon: this.icons.mdiLightningBolt });
        this.items.push({ to: "/flight-info", label: "Flight info", icon: this.icons.mdiAirplaneSearch });
        this.itemsUpdateDone = true;
      }
    } else {
      this.itemsUpdateDone = false;
    }
  }
}
