// tslint:disable
/**
 * Next Trader
 * For the BIG money :D
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from "rxjs";
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse } from "../runtime";
import {
  CalculateRequest,
  CalculateRequestStage2,
  CalculateRequestStage2All,
  GetIndicatorConfigsResult,
  StatsDetailedResultAll,
} from "../models";

export interface PostAppStrategyfinetuneCalculatestatsRequest {
  calculateRequest?: CalculateRequest;
}

export interface PostAppStrategyfinetuneCalculatestatsfakeRequest {
  calculateRequestStage2?: CalculateRequestStage2;
}

export interface PostAppStrategyfinetuneCalculatestatssimpleRequest {
  calculateRequestStage2?: CalculateRequestStage2;
}

export interface PostAppStrategyfinetuneCalculatestatsstage1Request {
  calculateRequestStage2?: CalculateRequestStage2;
}

export interface PostAppStrategyfinetuneCalculatestatsstage2Request {
  calculateRequestStage2?: CalculateRequestStage2;
}

export interface PostAppStrategyfinetuneCalculatestatsstage2allRequest {
  calculateRequestStage2All?: CalculateRequestStage2All;
}

export interface PostAppStrategyfinetuneExitstatsRequest {
  calculateRequest?: CalculateRequest;
}

export interface PostAppStrategyfinetuneGetindicatorconfigsRequest {
  calculateRequestStage2All?: CalculateRequestStage2All;
}

/**
 * no description
 */
export class StrategyFinetuneApi extends BaseAPI {
  /**
   */
  postAppStrategyfinetuneCalculatestats({
    calculateRequest,
  }: PostAppStrategyfinetuneCalculatestatsRequest): Observable<StatsDetailedResultAll>;
  postAppStrategyfinetuneCalculatestats(
    { calculateRequest }: PostAppStrategyfinetuneCalculatestatsRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StatsDetailedResultAll>>;
  postAppStrategyfinetuneCalculatestats(
    { calculateRequest }: PostAppStrategyfinetuneCalculatestatsRequest,
    opts?: OperationOpts
  ): Observable<StatsDetailedResultAll | RawAjaxResponse<StatsDetailedResultAll>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StatsDetailedResultAll>(
      {
        url: "/api/strategyFinetune/calculateStats",
        method: "POST",
        headers,
        body: calculateRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneCalculatestatsfake({
    calculateRequestStage2,
  }: PostAppStrategyfinetuneCalculatestatsfakeRequest): Observable<StatsDetailedResultAll>;
  postAppStrategyfinetuneCalculatestatsfake(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatsfakeRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StatsDetailedResultAll>>;
  postAppStrategyfinetuneCalculatestatsfake(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatsfakeRequest,
    opts?: OperationOpts
  ): Observable<StatsDetailedResultAll | RawAjaxResponse<StatsDetailedResultAll>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StatsDetailedResultAll>(
      {
        url: "/api/strategyFinetune/calculateStatsFake",
        method: "POST",
        headers,
        body: calculateRequestStage2,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneCalculatestatssimple({
    calculateRequestStage2,
  }: PostAppStrategyfinetuneCalculatestatssimpleRequest): Observable<StatsDetailedResultAll>;
  postAppStrategyfinetuneCalculatestatssimple(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatssimpleRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StatsDetailedResultAll>>;
  postAppStrategyfinetuneCalculatestatssimple(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatssimpleRequest,
    opts?: OperationOpts
  ): Observable<StatsDetailedResultAll | RawAjaxResponse<StatsDetailedResultAll>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StatsDetailedResultAll>(
      {
        url: "/api/strategyFinetune/calculateStatsSimple",
        method: "POST",
        headers,
        body: calculateRequestStage2,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneCalculatestatsstage1({
    calculateRequestStage2,
  }: PostAppStrategyfinetuneCalculatestatsstage1Request): Observable<StatsDetailedResultAll>;
  postAppStrategyfinetuneCalculatestatsstage1(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatsstage1Request,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StatsDetailedResultAll>>;
  postAppStrategyfinetuneCalculatestatsstage1(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatsstage1Request,
    opts?: OperationOpts
  ): Observable<StatsDetailedResultAll | RawAjaxResponse<StatsDetailedResultAll>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StatsDetailedResultAll>(
      {
        url: "/api/strategyFinetune/calculateStatsStage1",
        method: "POST",
        headers,
        body: calculateRequestStage2,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneCalculatestatsstage2({
    calculateRequestStage2,
  }: PostAppStrategyfinetuneCalculatestatsstage2Request): Observable<StatsDetailedResultAll>;
  postAppStrategyfinetuneCalculatestatsstage2(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatsstage2Request,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StatsDetailedResultAll>>;
  postAppStrategyfinetuneCalculatestatsstage2(
    { calculateRequestStage2 }: PostAppStrategyfinetuneCalculatestatsstage2Request,
    opts?: OperationOpts
  ): Observable<StatsDetailedResultAll | RawAjaxResponse<StatsDetailedResultAll>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StatsDetailedResultAll>(
      {
        url: "/api/strategyFinetune/calculateStatsStage2",
        method: "POST",
        headers,
        body: calculateRequestStage2,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneCalculatestatsstage2all({
    calculateRequestStage2All,
  }: PostAppStrategyfinetuneCalculatestatsstage2allRequest): Observable<StatsDetailedResultAll>;
  postAppStrategyfinetuneCalculatestatsstage2all(
    { calculateRequestStage2All }: PostAppStrategyfinetuneCalculatestatsstage2allRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<StatsDetailedResultAll>>;
  postAppStrategyfinetuneCalculatestatsstage2all(
    { calculateRequestStage2All }: PostAppStrategyfinetuneCalculatestatsstage2allRequest,
    opts?: OperationOpts
  ): Observable<StatsDetailedResultAll | RawAjaxResponse<StatsDetailedResultAll>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<StatsDetailedResultAll>(
      {
        url: "/api/strategyFinetune/calculateStatsStage2All",
        method: "POST",
        headers,
        body: calculateRequestStage2All,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneExitstats({ calculateRequest }: PostAppStrategyfinetuneExitstatsRequest): Observable<void>;
  postAppStrategyfinetuneExitstats(
    { calculateRequest }: PostAppStrategyfinetuneExitstatsRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>>;
  postAppStrategyfinetuneExitstats(
    { calculateRequest }: PostAppStrategyfinetuneExitstatsRequest,
    opts?: OperationOpts
  ): Observable<void | RawAjaxResponse<void>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<void>(
      {
        url: "/api/strategyFinetune/exitStats",
        method: "POST",
        headers,
        body: calculateRequest,
      },
      opts?.responseOpts
    );
  }

  /**
   */
  postAppStrategyfinetuneGetindicatorconfigs({
    calculateRequestStage2All,
  }: PostAppStrategyfinetuneGetindicatorconfigsRequest): Observable<GetIndicatorConfigsResult>;
  postAppStrategyfinetuneGetindicatorconfigs(
    { calculateRequestStage2All }: PostAppStrategyfinetuneGetindicatorconfigsRequest,
    opts?: OperationOpts
  ): Observable<RawAjaxResponse<GetIndicatorConfigsResult>>;
  postAppStrategyfinetuneGetindicatorconfigs(
    { calculateRequestStage2All }: PostAppStrategyfinetuneGetindicatorconfigsRequest,
    opts?: OperationOpts
  ): Observable<GetIndicatorConfigsResult | RawAjaxResponse<GetIndicatorConfigsResult>> {
    const headers: HttpHeaders = {
      "Content-Type": "application/json",
      ...(this.configuration.apiKey && { "X-AUTH-TOKEN": this.configuration.apiKey("X-AUTH-TOKEN") }), // APIKeyHeader authentication
    };

    return this.request<GetIndicatorConfigsResult>(
      {
        url: "/api/strategyFinetune/getIndicatorConfigs",
        method: "POST",
        headers,
        body: calculateRequestStage2All,
      },
      opts?.responseOpts
    );
  }
}
