
import Component from "vue-class-component";
import Vue from "vue";
import { namespace } from "vuex-class";
import { mapGetters } from "vuex";
import { mdiGoogle, mdiFacebook, mdiLogout, mdiEmail, mdiAccount, mdiLock } from "@mdi/js";
import UserCard from "@/components/UserPage/UserCard.vue";

const ns = "profile";
const profileModule = namespace(ns);

@Component({
  components: { UserCard },
  computed: {
    ...mapGetters(ns, {
      user: "user",
    }),
  },
  metaInfo: {
    meta: [{ name: "description", content: "Next Trader description" }],
  },
})
export default class UserPage extends Vue {
  @profileModule.Action loginWithGoogle: any;
  @profileModule.Action loginWithFacebook: any;
  @profileModule.Action loginWithEmail: any;
  @profileModule.Action logout: any;

  icons = {
    mdiGoogle,
    mdiFacebook,
    mdiEmail,
    mdiLogout,
    mdiAccount,
    mdiLock,
  };

  email = "";
  password = "";

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
