import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vue-rx";
import "@/plugins/api";
import "@/plugins/vue2-perfect-scrollbar";
import "@/plugins/vue-numeral-filter";
import "@/plugins/vue-echarts-ts";
import "@/plugins/vue-formatDate-filter";
import "@/plugins/dayjs";
import store from "@/store";
import "./registerServiceWorker";
import firebase from "@/plugins/firebase";
import "@/directives/colorUpdate";
import "@/directives/nxSticky";
import "@/directives/preventChromeZoom";
import "@/directives/arbitrageProfitColored";

Vue.config.productionTip = false;
Vue.config.devtools = false;

firebase.init();

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
