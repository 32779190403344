import { VueConstructor, PluginObject } from "vue";
import VueEchartsComponent from "./VueEcharts.vue";

const vueEchartsComponent = {
  install(Vue: VueConstructor) {
    Vue.component("VueEcharts", VueEchartsComponent);
  },
} as PluginObject<any>;

export default vueEchartsComponent;
